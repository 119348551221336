<script setup>
import LeftArrow from '../../../components/Icons/LeftArrow.vue';
import RightArrow from '../../../components/Icons/RightArrow.vue';
import whatsappLogo from '../../../components/Icons/whatsappLogo.vue';
import { useWhatsApp } from '../../../reuseFun/sendWhatsapp';
import { ref } from 'vue';

const { sendDetail } = useWhatsApp();

const car_images = ref([
  { name: 'Mercedes Benz', description: '10,000 PKR/day', image: require('../../../assets/images/About_Premium_Auto_Rentals/Mercedes_Benz.png') },
  { name: 'Toyota Revo', description: '10,000 PKR/day', image: require('../../../assets/images/About_Premium_Auto_Rentals/Revo.png') },
  { name: 'Land Cruiser V8', description: '10,000 PKR/day', image: require('../../../assets/images/About_Premium_Auto_Rentals/Landcruiser_V8.png') },
  { name: 'Audi', description: '10,000 PKR/day', image: require('../../../assets/images/Featured_car/Audi_a4_white.png') },
  { name: 'Hilux Vigo', description: '10,000 PKR/day', image: require('../../../assets/images/Featured_car/Hilux_Vigo.png') },
]);

</script>
<template>
  <div class="text-wrapper-heading my-3" id="about_us">About Premium Auto Rentals</div>

  <div class="premium-auto d-flex gap-3 mb-5">

    <div class="overlap-group-2">
      <img class="premium-image" alt="premium image" src="../../../assets/images/premium_img.jpg" />
    </div>

    <div class="premium-auto-text">
      <div class="text-wrapper-34">Premium Auto Rentals</div>
      <div class="d-flex justify-content-between align-items-center mb-1 gap-2 flex-wrap">
        <p class="text-wrapper-35 m-0">Luxury In Every Mile</p>

        <div id="showMobile">
          <div class="d-flex align-items-center frame-8 gap-1 mobile-view-btn">
            <whatsappLogo :size="12" />
            <div class="text-wrapper-20">WhatsApp</div>
          </div>
        </div>

      </div>
      <p class="text-wrapper-35">
        At Premium Auto Rentals, we offer a premium selection of luxury and armored vehicles. Our fleet ensures both style and security, catering to clients who demand the best. Whether for special occasions or enhanced protection, we provide a seamless, top-tier rental experience.
      </p>
    </div>

    <div class="d-flex justify-content-end hide-btn" style="flex: 1;">
      <div class="d-flex align-items-center frame-8" @click="sendDetail">
        <div class="text-wrapper-20">WhatsApp</div>
        <img class="logos-whatsapp-icon" alt="Logos whatsapp icon"
          src="https://c.animaapp.com/W8NNSxwc/img/logos-whatsapp-icon.svg" />
      </div>
    </div>

  </div>

  <!-- <div class="slider mb-5">

    <div ref="slidesRef" class="about-slides gap-3">

      <div v-for="(image, index) in car_images" class="d-flex flex-column slide">
        <img class="image mb-2" :src="image.image">
        <div class="text-wrapper-34">{{ image.name }}</div>
        <div class="text-wrapper-35">{{ image.description }}</div>
      </div>

    </div>

    <button v-if="currentSlide > 0" class="position-absolute prev" @click="moveScroll(-1)">
      <LeftArrow />
    </button>
    <button class="position-absolute next" @click="moveScroll(1)">
      <RightArrow />
    </button>

  </div> -->
</template>

<script>
export default {
  name: "AboutPremiumAuto",
};
</script>

<style>
.overlap-group-2 {
  height: 128px;
}

.premium-image {
  height: 128px;
  object-fit: cover;
  width: 128px;
}

.premium-auto-text {
  max-width: 585px;
}

.mobile-view-btn {
  background-color: #961213;
  border-radius: 12px;
  height: 21px;
  /* width: 75px; */
  padding: 0px 10px;
  cursor: pointer;
}

@media (max-width: 700px) {
  .hide-btn {
    display: none !important;
  }

  .premium-auto-text {
    max-width: 100%;
  }

  .text-wrapper-20 {
    font-size: 12px;
  }
}

@media (max-width: 512px) {
  .premium-image {
    height: 80px;
    object-fit: cover;
    width: 80px;
    transition: all 0.5s linear;
  }
}
</style>
