<script setup>
import LeftArrow from '../../../components/Icons/LeftArrow.vue';
import RightArrow from '../../../components/Icons/RightArrow.vue';
import { ref, onMounted, onBeforeUnmount } from 'vue';
import router from '../../../router';

const car_images = ref([
  { name: 'Luxury  Vehicles', description: 'Spacious and Stylish', image: require('../../../assets/images/Car_categories/audi.png')},
  { name: 'Armored Vehicles', description: 'B6+ Spec Armoured. Bomb Proof', image: require('../../../assets/images/Car_categories/land_cruiser_2.png')},
  { name: 'Security Services', description: 'Power, Protection, Precision', image: require('../../../assets/images/Car_categories/vigo.png')}
]);

const currentSlide = ref(0);
const slidesRef = ref(null);

const scrollMove = (direction) => {
  const slides = document.querySelector('.slides');

  const totalSlides = car_images.value.length;

  currentSlide.value += direction;

  if (currentSlide.value < 0) {
    console.log('if')
    // currentSlide.value = totalSlides - 1;
    // slides.append(slides.firstElementChild);
    currentSlide.value = 0;
  } else if (currentSlide.value === totalSlides - 2) {
    console.log('elseif')
    currentSlide.value = 0;
    slides.prepend(slides.firstElementChild);
  }

  // slides.style.transform = `translateX(-${currentSlide.value * 33}%)`;
  const slideWidth = slidesRef.value.querySelector('.slide').offsetWidth;
  slidesRef.value.style.transform = `translateX(-${currentSlide.value * (slideWidth + 12)}px)`;

}

const showCategory = (name) => {
  const category_name = name.replace(/\s+/g, '-').toLowerCase();
  router.push({name: 'category', params: {categoryName: category_name}});
}

</script>

<template>
  <div class="my-5">
    <div class="text-wrapper-heading mb-2" id="category">Car Categories</div>

    <div class="featured-cars">
    <div v-for="(car, index) in car_images" :key="index" class="d-flex flex-column cursor-pointer" @click="showCategory(car.name)">
      <img class="image mb-2" :src="car.image" />
      <div class="text-wrapper-11">{{ car.name }}</div>
      <div class="text-wrapper-12">{{ car.description }}</div>
    </div>

  </div>

  </div>
</template>

<style>
.slider {
  position: relative;
  width: 100%;
  overflow: hidden;

}

.slides,
.top-slides,
.about-slides,
.blog-slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slide {
  min-width: 33%;
  box-sizing: border-box;
}

@media (max-width: 640px) {
  .slide .image {
    height: 102px !important;
    transition: all 0.5s ease;
  }
}

@media (max-width: 720px) {
  .slide {
    min-width: 50%;
  }
}

@media (max-width: 333px) {
  .slide {
    min-width: 100%;
  }
}

.slider button {
  height: 18vh !important;
  top: 38%;

  transform: translateY(-50%);
  border: none;
  color: white;
  font-size: 18px;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.slide .image{
  background-position: 50% 50%;
  object-fit: cover;
  border-radius: 12px;
  height: 166px;
  /* width: 296px; */
}

.text-wrapper-34 {
  align-self: stretch;
  color: #f6f6f6;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  font-family: "Plus Jakarta Sans", Helvetica;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-wrapper-35 {
  align-self: stretch;
  color: #9393c6;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 21px;
  font-family: "Plus Jakarta Sans", Helvetica;
}

button {
  background-color: transparent;
  border: none;
}

.prev {
  left: -2px;
  background: linear-gradient(270deg, rgba(159, 159, 159, 0) 10.98%, #171717 100%);
}

.next {
  right: -2px !important;
  background: linear-gradient(90deg, rgba(159, 159, 159, 0) 10.98%, #171717 100%);

}

#image-4 {
  background-image: url(https://c.animaapp.com/W8NNSxwc/img/depth-7--frame-0-17@2x.png);
}

#image-5 {
  background-image: url(https://c.animaapp.com/W8NNSxwc/img/depth-7--frame-0-19@2x.png);
}

#image-6 {
  background-image: url(https://c.animaapp.com/W8NNSxwc/img/depth-7--frame-0-23@2x.png);
}

#image-7 {
  background-image: url(https://c.animaapp.com/W8NNSxwc/img/depth-7--frame-0-21@2x.png);
}

#image-8 {
  background-image: url(https://c.animaapp.com/W8NNSxwc/img/depth-7--frame-0-21@2x.png);
}
</style>
