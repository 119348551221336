<script setup>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import { ref } from 'vue'

const client_img = ref([
    {image: require('../../../assets/images/client_img/client_1.jpeg')},
    {image: require('../../../assets/images/client_img/client_2.jpeg')},
    {image: require('../../../assets/images/client_img/client_3.jpeg')},
    {image: require('../../../assets/images/client_img/client_4.jpeg')},
    {image: require('../../../assets/images/client_img/client_5.jpeg')},
    {image: require('../../../assets/images/client_img/client_6.jpeg')},
    {image: require('../../../assets/images/client_img/client_7.jpeg')},
])

const breakpoints = ref({
    280: {
        itemsToShow: 1,
        snapAlign: 'start',
    },
    480: {
        itemsToShow: 2,
        snapAlign: 'start',
    },
    880: {
        itemsToShow: 4,
        snapAlign: 'start',
    },
    
})
</script>
<template>
    
  <div class="text-wrapper-heading my-3" id="clients">Our Clients</div>
    <carousel class="mb-5" :items-to-show="4" :wrapAround="true" snapAlign="start" :autoplay="0" :breakpoints="breakpoints">
        <slide v-for="(slide, index) in client_img" :key="index">
            <img class="carousel__item" :src="slide.image" alt="img"/>
        </slide>
    
        <template #addons>
          <navigation />
          <pagination />
        </template>
      </carousel>
</template>
<style>
.carousel__item {
  height: 152px;
  width: 100%;
  /*object-fit: cover; */
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__pagination-button--active::after {
    background-color: #000 !important;
}
.carousel__pagination-button::after {
    background-color: #f6f6f6;
    border-radius: 50%;
    width: 10px;
    height: 10px;
}

</style>