<script setup>
import Facebookicon from '../../../components/Icons/Facebookicon.vue';
import Twittericon from '../../../components/Icons/Twittericon.vue';
import Linkdinicon from '../../../components/Icons/Linkdinicon.vue';
import Instaicon from '../../../components/Icons/Instaicon.vue';
import { useWhatsApp } from '../../../reuseFun/sendWhatsapp'
const { sendDetail } = useWhatsApp();

</script>
<template>
  <footer class="footer">
    <div class="group-2">

      <div class="d-flex align-items-center justify-content-center mb-4">
        <p class="text-wrapper-35 m-0">
          Questions? Chat with us on
          <span @click="sendDetail" class="text-wrapper-31" style="cursor: pointer;">WhatsApp</span>
        </p>
      </div>

      <div class="d-flex align-items-center justify-content-between gap-3">
        <div class="text-wrapper-35"><a class="text-decoration-none text-wrapper-32" href="#">Terms of Service</a></div>
        <div class="text-wrapper-35"><a class="text-decoration-none text-wrapper-32" href="#">Privacy Policy</a></div>
      </div>

      <div class="footers">
        <div class="d-flex align-items-center justify-content-center social-icons gap-3">
          <a href="#">
            <Instaicon />
          </a>
          <a href="#">
            <Twittericon />
          </a>
          <a href="#">
            <Facebookicon />
          </a>
          <a href="#">
            <Linkdinicon />
          </a>
        </div>
        <div class=" div-wrapper-4">
          <p class="text-wrapper-32">© 2024 Premium Auto Rentals</p>
        </div>
        <div class="div-wrapper-4">
          <p class="text-wrapper-32">Made with ❤️ <a class=" cursor-pointer text-decoration-none text-wrapper-32"
              href="https://arshisoft.com/" target="_blank">by
              ArshiSoft (Pvt) Ltd</a></p>
        </div>
      </div>
    </div>
  </footer>
</template>
<style>
.footer {
  background-color: transparent;
  height: 285px;
  /* left: 176px;
  position: absolute;
  top: 3491px; */
  width: 100%;
}

.footer .group-2 {
  height: 285px;
  position: relative;
  width: 100%;
}

.footer .questions-chat-with {
  align-self: stretch;
  color: #9393c6;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.footer .span {
  color: #9393c6;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 21px;
}

.footer .text-wrapper-31 {
  text-decoration: underline;
}

.footer .footers {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 40px 20px 0px 20px;
  /* left: 0;
  position: absolute;
  top: 37px; */
  width: 100%;
}

.footer .frame-14 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 24px 24px;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.footer .div-wrapper-3 {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 160px;
}

.footer .text-wrapper-32 {
  align-self: stretch;
  color: #9393c6;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.footer .social-icons {
  align-self: stretch;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.footer .div-wrapper-4 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}
</style>
