<script setup>
import { useWhatsApp } from '../../../reuseFun/sendWhatsapp'

const { sendDetail } = useWhatsApp();
</script>

<template>
  <div class="hero-image my-4">
    <div class="position-absolute">
      <p class="text-wrapper-9">The world’s finest vehicles, delivered to your door</p>
      <p class="p f-16">
        Find the perfect car for every drive.
        <br/>
        We have the largest selection of luxury vehicles.
      </p>
      <button class="button">
        <div class="text">
          <div class="text-wrapper-10 f-16 d-flex align-items-center gap-2" @click="sendDetail">
            <span>Book Now</span>
            <img class="logos-whatsapp-icon" alt="Logos whatsapp icon"
              src="https://c.animaapp.com/W8NNSxwc/img/logos-whatsapp-icon.svg" />
          </div>
        </div>
      </button>
    </div>

    <div class="d-flex align-items-center gap-3 mobile-view-position">
      <button class="button">
        <div class="text">
          <div class="text-wrapper-10 f-14" @click="sendDetail">Book Now</div>
        </div>
      </button>
      <p class="p m-0 f-12" style="line-height: 14px;">
        Find the perfect car for every drive.
      </p>
    </div>
  </div>
</template>

<style>
.hero-image {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.4) 100%);
  background-image: url(../../../assets/images/main_hero_image.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 12px;
  height: 480px;
  overflow: hidden;
  position: relative;
  filter: grayscale(30%);
  width: 100%;
  font-family: "Plus Jakarta Sans", Helvetica;
}

.hero-image .position-absolute {
  top: 43%;
  transform: translateY(-50%);
  left: 56px;
  width: 82%;
}

.hero-image .p,
.mobile-view-position .p {
  color: #000;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  /* width: 80%; */
  /* text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5); */
  text-shadow: 1px 1px 2px #f6f6f6;
}

.hero-image .text-wrapper-9 {
  color: #000;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 48px;
  font-weight: 800;
  letter-spacing: -2px;
  line-height: 60px;
  /* width: 82%; */
}

.hero-image .button {
  all: unset;
  align-items: center;
  background-color: #961213;
  border-radius: 12px;
  box-sizing: border-box;
  display: flex;
  height: 46px;
  justify-content: center;
  max-width: 480px;
  overflow: hidden;
  padding: 0px 20px;
  /* position: absolute; */
  /* top: 389px; */
  left: 24px;
  /* width: 116px; */
  cursor: pointer;
}

.hero-image .text {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-left: -2.5px;
  margin-right: -2.5px;
  position: relative;
}

.hero-image .text-wrapper-10 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  color: #f6f6f6;
  display: -webkit-box;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-overflow: ellipsis;
}

.mobile-view-position {
  position: absolute;
  bottom: 12px;
  left: 24px;
}

.mobile-view-position .button {
  padding: 0 12px;
  height: 32px;
}

.mobile-view-position .button,
.mobile-view-position .p {
  display: none;
}

@media (max-width: 880px) {
  .hero-image .text-wrapper-9 {
    font-size: 32px;
    line-height: 40px;
  }
}

@media (max-width: 720px) {
  .hero-image .position-absolute {
    left: 24px;
    transition: all 0.5s ease-in-out;
  }

  .hero-image .button,
  .hero-image .p {
    display: none;
  }

  .mobile-view-position .button,
  .mobile-view-position .p {
    transition: all 0.5s ease-in-out;
    display: block;
  }
}

@media (max-width: 440px) {
  .hero-image .text-wrapper-9 {
    font-size: 24px;
    line-height: 28px;
  }

  .hero-image {
    height: 240px;
    transition: all 1s linear;
  }
}
</style>
