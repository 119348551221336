<script setup>
import LeftArrow from '../../../components/Icons/LeftArrow.vue';
import RightArrow from '../../../components/Icons/RightArrow.vue';
import { ref } from 'vue';

const car_images = ref([
  { name: 'Landcruiser Armored', description: 'B6+ Spec Armoured. Bomb Proof', image: require('../../../assets/images/Car_categories/land_cruiser_1.png') },
  { name: 'Mercedes Benz S Class', description: '', image: require('../../../assets/images/Car_categories/mercedes.png') },
  { name: 'Toyota Revo', description: '', image: require('../../../assets/images/Car_categories/revo.png') },
  { name: 'Hilux Vigo', description: '', image: require('../../../assets/images/Car_categories/vigo.png') },
  { name: 'Audi A4', description: '', image: require('../../../assets/images/Car_categories/audi.png') },
]);

const currentSlide = ref(0);
const slidesRef = ref(null);

const moveScroll = (direction) => {
  const slides = document.querySelector('.top-slides');
  console.log('slides', slides)
  const totalSlides = car_images.value.length;

  currentSlide.value += direction;

  if (currentSlide.value < 0) {
    currentSlide.value = 0;
  }
  else if (currentSlide.value === totalSlides - 2) {
    currentSlide.value = 0;
    slides.prepend(slides.firstElementChild)
  }

  // slides.style.transform = `translateX(-${currentSlide.value * 35}%)`;
  const slideWidth = slidesRef.value.querySelector('.slide').offsetWidth;
  slidesRef.value.style.transform = `translateX(-${currentSlide.value * (slideWidth + 12)}px)`;

}

</script>
<template>
  <div class="mb-5">
    <div class="text-wrapper-heading mb-2" id="top_cars">Top Cars</div>

    <div class="slider">

      <div ref="slidesRef" class="top-slides gap-3">

        <div v-for="(image, index) in car_images" class="d-flex flex-column slide">
          <img class="image mb-2" :src="image.image">
          <div class="text-wrapper-34">{{ image.name }}</div>
          <div class="text-wrapper-35">{{ image.description }}</div>
        </div>

      </div>

      <button v-if="currentSlide > 0" class="position-absolute prev" @click="moveScroll(-1)">
        <LeftArrow />
      </button>
      <button class="position-absolute next" @click="moveScroll(1)">
        <RightArrow />
      </button>

    </div>

  </div>

</template>
