<script setup>
import TopMenu from '../screens/Home/sections/TopMenu.vue'
import Footer from '../screens/Home/sections/Footer.vue'
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';
const route = useRoute();

const category_name = computed(() => {
    return route.params.categoryName.replace(/-/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
});

const car_images = ref([
  { name: 'Toyota Revo', price: '10,000 PKR/day', image: new URL('../assets/images/Featured_car/Revo.png', import.meta.url).href },
  { name: 'Land Cruiser V8', price: '10,000 PKR/day', image: new URL('../assets/images/Featured_car/Untitled-1.png', import.meta.url).href },
  { name: 'Audi A4 White', price: '10,000 PKR/day', image: new URL('../assets/images/Featured_car/Audi_a4_white.png', import.meta.url).href },
  { name: 'Mercedes Benz', price: '10,000 PKR/day', image: new URL('../assets/images/Featured_car/Mercedes_Benz.png', import.meta.url).href },
  { name: 'Toyota Hilux Vigo', price: '10,000 PKR/day', image: new URL('../assets/images/Featured_car/Hilux_Vigo.png', import.meta.url).href },
  { name: 'Toyota V6', price: '10,000 PKR/day', image: new URL('../assets/images/Featured_car/V6_Highlander.png', import.meta.url).href },
  { name: 'Toyota V6', price: '10,000 PKR/day', image: new URL('../assets/images/Featured_car/V6_Highlander.png', import.meta.url).href },
  { name: 'Toyota V6', price: '10,000 PKR/day', image: new URL('../assets/images/Featured_car/V6_Highlander.png', import.meta.url).href },
]);
</script>
<template>
    <TopMenu />
    <div class="home">
        <div class="div-3">
            <div class="border-bottom mb-4">
                <h2 class="text-wrapper-heading text-gray py-3 m-0">Categories/
                    <span class="text-wrapper-heading">{{ category_name }}</span>
                </h2>
            </div>

            <div class="featured-cars mb-5">
                <div v-for="(car, index) in car_images" :key="index" class="d-flex flex-column">
                    {{ console.log(car.image) }}
                    <img class="image mb-2" :src="car.image" />
                    <div class="text-wrapper-11">{{ car.name }}</div>
                    <div class="text-wrapper-12">{{ car.price }}</div>
                </div>

            </div>
            <Footer />
        </div>
    </div>
</template>
<style>
.border-bottom {
    border-bottom: 1px solid #FFFFFF;
}
.text-gray {
    color: #A3A3A3;
}
</style>