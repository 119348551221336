import { createRouter, createWebHistory } from "vue-router";
import Home from '../pages/Home.vue'
import Category from '../pages/Category.vue'

const scrollBehavior = (to, from, savedPosition) => {
    if (savedPosition) {
        return savedPosition;
    }
    else if (to.hash) {
        return {
            el: to.hash,
            behavior: 'smooth',
        }
    }
    else {
        return {top: 0}
    }
}

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior: scrollBehavior,
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
        },
        {
            path: '/category/:categoryName',
            name: 'category',
            component: Category,
        },
    ],
})
export default router